<template>
	<div class="container">
		<div class="title">基础信息</div>
		<a-form :model="detail.basic" layout="inline">
			<a-form-item label="解决方案名称">
				<div class="value">{{detail.basic.name}}</div>
			</a-form-item>
			<a-form-item label="解决方案类型">
				<div class="value">{{businessTypeList[detail.basic.businessType]}}</div>
			</a-form-item>
			<a-form-item label="包含应用" :label-col=" { style: { width: '110px' } }">
				<div class="value" v-if="detail.basic.apps">
					<span v-for="(item,index) in detail.basic.apps" :key="index">
						{{item.name}}{{item.required?"(必选)":""}}&nbsp;&nbsp; </span>
				</div>
			</a-form-item>
			<a-form-item label="解决方案使用场景及优势">
				<div class="value" v-html="detail.basic.description"></div>
			</a-form-item>
		</a-form>
		<template v-if="detail.removeReason">
			<div class="title">下架原因</div>
			<a-form :model="detail.removeReason" layout="inline">
				<a-form-item label="填写时间">
					<div class="">{{detail.removeReason.applyTime}}</div>
				</a-form-item>
				<a-form-item label="原因">
					<div class="">{{detail.removeReason.reason}}</div>
				</a-form-item>
			</a-form>
		</template>
		<div class="title">历史审批记录</div>
		<a-table :columns="historyColumns" :data-source="detail.history" :pagination="false" size="small">
			<span slot="action" slot-scope="text">{{text.description}}</span>
		</a-table>
		<template v-if="detail.operation.remove||detail.operation.reject||detail.operation.pass">
			<a-textarea class="formInputWidth" :maxLength="100" v-model="result" placeholder="填写审批结果" />
		</template>
		<div class="flex-row">
			<a-button @click="onRemove" type="danger" v-if="detail.operation.remove">下架</a-button>
			<a-button @click="onReject" type="danger" v-if="detail.operation.reject">驳回</a-button>
			<a-button @click="onPass" type="primary" v-if="detail.operation.pass">通过</a-button>
		</div>
	</div>
</template>
<script>
	import { GetSecretObsClient, getAkAndSk } from "../../utils/obs.js"
	import { Modal } from 'ant-design-vue'
	import pagination from "@/mixins/Pagination"
	import { solutionCheckApi } from "@/api/solutionCheckApi.js";
	export default {
		mixins: [pagination],
		components: {},
		data() {
			return {
				businessTypeList: {
					'1': '生产管控',
					'2': '安全环保',
					'3': '供应链管理',
					'4': '能源管理',
					'5': '资产管理',
					'0': '其他'
				}, //业务类型
				loading: false,
				basic: {
					id: null,
					name: "",
					description: "", // 解决方案描述
					apps: '', //包含应用列表
				},
				name: "",
				type: "",
				status: null,
				sourceData: [],
				result: '',
				detail: {
					basic: {
						businessType: 0, // 业务类型 
					},
					history: [],
					removeReason: null,
					operation: {}
				},
				operatedLine: 0,
				historyColumns: [{
					title: "审批账号",
					dataIndex: "accountName",
					key: "accountName"
				}, {
					title: "审批时间",
					dataIndex: "auditTime",
					key: "auditTime"
				}, {
					title: "审批类型",
					dataIndex: "action",
					key: "action",
					scopedSlots: { customRender: "action" }
				}, {
					title: "审批结果",
					key: "result",
					dataIndex: "result"
				}],
			};
		},
		mounted() {
			this.getSourceData()
		},
		methods: {
			//华为云下载的三个方法
			async setSKandAK(fileName) {
				let ak = await getAkAndSk("/front-api/cloud-platform-backend-facade/v1/obs/akAndSk");
				if (ak) {
					//缓存至本地临时仓库
					window.sessionStorage.setItem("securitytoken", ak.securitytoken);
					window.sessionStorage.setItem("secret", ak.secret);
					window.sessionStorage.setItem("access", ak.access);
					window.sessionStorage.setItem("expires_at", ak.expires_at);
					//初始化传进来的文件列表
					this.handleDown(fileName);
				} else {
					this.$message.error("临时密钥获取失败");
				}
			},
			async handleDown(fileName) {
				let sk = {
					securitytoken: window.sessionStorage.getItem("securitytoken"),
					secret: window.sessionStorage.getItem("secret"),
					access: window.sessionStorage.getItem("access"),
				};
				let tempfile = await GetSecretObsClient(fileName, "datarun-cloud-sec", sk);
				let link = document.createElement("a");
				fetch(tempfile.fileAddress).then((res) => res.blob()).then((blob) => {
					// 将链接地址字符内容转变成blob地址
					link.href = URL.createObjectURL(blob);
					link.download = tempfile.fileName;
					document.body.appendChild(link);
					link.click();
				});
			},
			async download(item) {
				let fileName = item.substring(item.lastIndexOf("com") + 4); //文件名
				const expires = sessionStorage.getItem("expires_at");
				const securityToken = sessionStorage.getItem("securitytoken");
				//是否有临时token等信息
				if (expires && securityToken) {
					if (new Date(expires).getTime() < Date.now()) {
						//过期，请求临时token
						await this.setSKandAK(fileName);
					} else {
						//未过期，初始化
						this.handleDown(fileName);
					}
				} else {
					//为满足，请求临时令牌
					await this.setSKandAK(fileName);
				}
			},
			//查询数据接口
			getSourceData() {
				this.loading = true
				solutionCheckApi.getSolutionDetail({
					id: this.$route.query.id
				}).then((res) => {
					if (res.success) {
						this.loading = false
						Object.keys(this.detail).forEach(key => {
							if (res.data[key]) {
								if (key === "history") {
									if (this.detail.history.length === 0) {
										this.detail.history.push(res.data.history)
									}
									this.setKey(this.detail.history)
								} else {
									this.detail[key] = res.data[key]
								}
							}
						})
					} else {
						this.loading = false
						this.$message.error(res.errorMsg)
					}
				})
			},
			setKey(arr) {
				if (arr) {
					arr.forEach((item, index) => {
						item.sort = index + 1
						item.key = index
					})
				}
			},
			//下架
			onRemove() {
				Modal.confirm({
					title: '是否确认下架此解决方案',
					content: `解决方案名称：${this.detail.basic.name}`,
					onOk: () => {
						solutionCheckApi.RemoveSolution({
							id: this.$route.query.id,
							result: this.result
						}).then(res => {
							if (res.success) {
								this.result = ''
								this.$message.success("下架成功")
								this.getSourceData()
							} else {
								this.$message.error(res.errorMsg)
							}
						})
					}
				})
			},
			//驳回
			onReject() {
				Modal.confirm({
					title: '是否确认驳回此解决方案',
					content: `解决方案名称：${this.detail.basic.name}`,
					onOk: () => {
						solutionCheckApi.RejectSolution({
							id: this.$route.query.id,
							result: this.result
						}).then(res => {
							if (res.success) {
								this.result = ''
								this.$message.success("当前解决方案已驳回")
								this.getSourceData()
							} else {
								this.$message.error(res.errorMsg)
							}
						})
					}
				})
			},
			//通过
			onPass() {
				Modal.confirm({
					title: '是否确认通过此解决方案',
					content: `解决方案名称：${this.detail.basic.name}`,
					onOk: () => {
						solutionCheckApi.PassSolution({
							id: this.$route.query.id,
							result: this.result
						}).then(res => {
							if (res.success) {
								this.result = ''
								this.$message.success("解决方案审核通过！")
								this.getSourceData()
							} else {
								this.$message.error(res.errorMsg)
							}
						})
					}
				})
			}
		},
	};
</script>
<style lang="scss" scoped>
	.container {
		padding-left: 3rem;

		.icon {
			width: 2.4rem;
			height: 2.4rem;
		}

		.screenshots {
			width: 10rem;
		}

		.flex-row {
			.ant-btn {
				width: 8rem;
				margin-right: 3rem;
			}
		}

		.title {
			// smargin-left: 5rem;
			line-height: 1.2rem;
			height: 1.2rem;
			font-size: 1.2rem;
			margin-bottom: 2rem;
			margin-top: 2rem;
		}

		.title:before {
			content: '';
			display: inline-block;
			width: 0.3rem;
			height: 100%;
			background-color: #1a8af3;
			margin-right: .5rem;
			margin-bottom: -.15rem;
		}

		/deep/ label {
			font-size: 1rem;
			font-weight: 600 !important;
		}

		.value {
			width: 30rem;
		}

		.formInputWidth {
			width: 40rem;
			height: 10rem;
			margin-top: 3rem;
			margin-bottom: 3rem;
		}
	}
</style>
